const SERVER_BASE_URL = 'https://service.customer.tat.toyota.itsd-consulting.services';
export const environment = {
  production: false,
  logEnabled: true,
  showDebug: false,
  name: 'STAGE_TAT',
  country: 'AT',
  eweUrl: 'https://service.tat.toyota.itsd-consulting.services/toyota/ewe/{eweVersion}/html',
  dsiUrl: 'https://service.tat.toyota.itsd-consulting.services/toyota/dsi/{eweVersion}/pdf',
  endpoints: {
    getCustomerConsent: `${SERVER_BASE_URL}/customer-consent`,
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
